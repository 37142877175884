import Typed from 'typed.js';
import { tns } from 'tiny-slider/src/tiny-slider';
import Swiper from 'swiper/bundle';

$ = jQuery;
// Swiper Slider Start
$('.js-slider').each((i, el) => {
  const sliderSwiper = new Swiper(el.querySelector('.swiper-container'), {
    slidesPerView: 1,
    resistanceRatio: 0,
    loop: true,
    pagination: {
      el: el.querySelector('.swiper-pagination'),
      clickable: true,
    },
    navigation: {
      nextEl: el.querySelector('.slider__next'),
      prevEl: el.querySelector('.slider__prev'),
    },
  });
});
// Swiper Slider End

// Homepage Work Slider Start
var swiper = new Swiper('.home--case--studies__container', {
  slidesPerView: 1,
  slidesPerGroup: 1,
  spaceBetween: 0,
  keyboard: {
    enabled: true,
  },
  navigation: {
    nextEl: '.slider__next',
    prevEl: '.slider__prev',
  },
  breakpoints: {
    768: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 50,
    },
  },
});
// Homepage Work Slider End

const $win = $(window);
const $doc = $(document);
const $main = $('.main');
const $header = $('.header');

$doc.on('ready', () => {
  if ($('.case-studies__track').length) {
    var slider = tns({
      container: '.case-studies__track',
      items: 1,
      // slideBy: 1,
      autoplay: true,
      autoplayButtonOutput: false,
      edgePadding: 0,
      gutter: 0,
      fixedWidth: 800,
      center: true,
      mouseDrag: true,
      touch: true,
      nav: false,
      prevButton: '#cs__prev',
      nextButton: '#cs__next',
      responsive: {
        1080: {
          fixedWidth: 800,
          items: 1,
        },
        767: {
          fixedWidth: 800,
          viewportMax: 800,
        },
        480: {
          fixedWidth: 450,
          viewportMax: 450,
        },
        300: {
          fixedWidth: 380,
          viewportMax: 380,
        },
      },
    });
  }
});

$('.scroll-next').on('click', (e) => {
  e.preventDefault();
  console.log('scroll clicked');

  if ($main.length) {
    $('html, body').animate(
      {
        scrollTop: $main.offset().top - $header.outerHeight(),
      },
      700,
      'linear'
    );
  }
});

// Show main nav
$('.header .btn-nav').on('click', function (event) {
  $(this).toggleClass('active').closest('html').toggleClass('mobile-show');

  event.preventDefault();
});

// Make main navigation clickable
$('.nav').on('touchstart', 'a', function (e) {
  let parentEl = $(this).closest('li');
  let subMenu = parentEl.children('.sub-menu');

  parentEl.siblings().removeClass('hovered');

  if (!parentEl.hasClass('hovered') && subMenu.length > 0) {
    e.preventDefault();

    parentEl.addClass('hovered');
  }
});

$doc.on('touchstart', function (e) {
  if (!e.target.closest('.nav')) {
    $('.nav').find('li').removeClass('hovered');
  }
});

const mainTitle = document.querySelector('h1[data-typed]');

if (mainTitle) {
  const typed = new Typed(document.querySelector('.intro h1'), {
    strings: [mainTitle.dataset.typed],
    startDelay: 1500,
    typeSpeed: 40,
    showCursor: false,
    onComplete: () => {
      const subTitle = document.querySelector('p[data-typed]');

      if (subTitle) {
        const typed = new Typed(document.querySelector('.intro p'), {
          strings: [subTitle.dataset.typed],
          startDelay: 500,
          typeSpeed: 40,
          showCursor: false,
        });
      }
    },
  });
}

// Header behavior
$win.on('scroll', () => {
  const winT = $win.scrollTop();
  $header.toggleClass('header--fixed', $win.scrollTop() >= 1);
});

//ANIMATIONS
$win.on('load', () => {
  // Animate 2nd Row of Our Work on load
  $('.case-studies__grid-image__content:nth-child(4)')
    .addClass('aos-animate')
    .attr('data-aos-delay', '500');
  $('.case-studies__grid-image__content:nth-child(5)')
    .addClass('aos-animate')
    .attr('data-aos-delay', '500');
  $('.case-studies__grid-image__content:nth-child(6)')
    .addClass('aos-animate')
    .attr('data-aos-delay', '500');
  // Animate 2nd Block Post on Load
});

/**
 * Scroll to Opening Section
 */
/*
$('.js-scroll').on('click', function(e){
	e.preventDefault();

	const scrollTarget = $(this).attr('href');

	if ($(scrollTarget).length) {
		$('html').animate({
			scrollTop: $(scrollTarget).offset().top - 400
		}, 700)
	}
});
*/

$('a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function (event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, '') ==
        this.pathname.replace(/^\//, '') &&
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('.page-anchor').css('top', '0');
        $('html, body').animate(
          {
            scrollTop: target.offset().top - 105,
          },
          1000,
          function () {
            // Callback after animation
            // Must change focus!
            var $target = $(target);
            $target.focus();
            if ($target.is(':focus')) {
              // Checking if the target was focused
              return false;
            } else {
              $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
              $target.focus(); // Set focus again
            }
          }
        );
      }
    }
  });
// Video Controls
// present on our work page
const video = document.getElementById('video');
let videoStarted = false;

if (video) {
  const videoControls = document.getElementById('video-controls');
  const videoWorks = !!document.createElement('video').canPlayType;
  if (videoWorks) {
    video.controls = false;
  }
  const playButton = document.querySelector('.video__play');
  function togglePlay() {
    if (!videoStarted) {
      // set video start time to 0, allowing you to specify a poster frame timestamp
      video.currentTime = 0;
      videoStarted = true;
    }
    if (video.paused || video.ended) {
      video.play();
      video.controls = true;
      video.classList.add('pointer');
      playButton.classList.add('hidden');
    } else {
      video.controls = false;
      playButton.classList.remove('hidden');
      video.pause();
    }
  }
  // after video is minimized from fullscreen, add the play button if the video is paused
  $('#video').on(
    'fullscreenchange webkitfullscreenchange mozfullscreenchange',
    function () {
      if ($(this).height() < 500 && this.paused) {
        video.controls = false;
        playButton.classList.remove('hidden');
      }
    }
  );
  function togglePauseOnVideoClick() {
    // allow pause only if not fullscreen
    if (!video.paused && $('#video').height() < 700) {
      video.pause();
      video.controls = false;
      video.classList.remove('pointer');
      playButton.classList.remove('hidden');
    }
  }
  function isInView(e) {
    var rect = e.getBoundingClientRect();
    return !(rect.top > $(window).height() || rect.bottom < 0);
  }
  $(document).on('scroll', function () {
    $('#video').each(function () {
      if (!isInView($(this)[0])) {
        if (!$(this)[0].paused) togglePlay();
      }
    });
  });
  playButton.addEventListener('click', togglePlay);
  video.addEventListener('click', togglePauseOnVideoClick);
}

// Careers page location animation

const rive = require('@rive-app/canvas');

document.addEventListener('DOMContentLoaded', function () {
  const riveInstances = [];
  // Layout for location animation
  let map_layout = new rive.Layout({
    fit: rive.Fit.FitWidth,
    alignment: rive.Alignment.TopCenter,
  });
  // Will allow for multiple animations to be created with some simple config info
  function createRiveInstance(config) {
    const { canvasId, artboard, stateMachine, triggerId } = config;
    const canvas = document.getElementById(canvasId);

    const riveInstance = new rive.Rive({
      src:
        '/wp-content/themes/xds/resources/images/animations/xds-website-animations.riv',
      canvas: canvas,
      autoplay: false,
      artboard: artboard,
      stateMachines: [stateMachine],
      layout: map_layout,
      onLoad: () => {
        riveInstance.resizeDrawingSurfaceToCanvas();
        setupScrollTrigger(riveInstance, stateMachine, triggerId);
      },
    });

    riveInstances.push(riveInstance);
  }

  // Hook into the progress input value from the rive file
  function useStateMachineInput(
    riveInstance,
    stateMachineName,
    inputName,
    initialValue
  ) {
    const input = riveInstance
      .stateMachineInputs(stateMachineName)
      .find((input) => input.name === inputName);
    if (input) {
      input.value = initialValue;
    }
    return input;
  }
  // Hook that progress input value up to GSAP's scrolltrigger
  function setupScrollTrigger(riveInstance, stateMachineName, triggerId) {
    const progressInput = useStateMachineInput(
      riveInstance,
      stateMachineName,
      'progress',
      0
    );

    // GSAP portion - timeline.
    // Would like to explore having the animation reverse on scroll past
    gsap.registerPlugin(ScrollTrigger);

    const animationTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: `#${triggerId}`,
        start: 'top center',
        end: 'bottom center',
        scrub: true,
      },
    });

    animationTimeline.to(progressInput, {
      value: 100,
      onUpdate: () => {
        riveInstance.play();
      },
      onStart: () => {
        riveInstance.play();
      },
      onComplete: () => {
        riveInstance.pause();
      },
    });
  }

  // Create a rive instance for each of any configurations listed below
  function initializeAnimations(configs) {
    configs.forEach((config) => {
      createRiveInstance(config);
    });
  }

  // We just have one now - we can add more in the future if we want
  const animationConfigs = [
    {
      canvasId: 'locations-canvas',
      artboard: 'production-2',
      stateMachine: 'State Machine 1',
      triggerId: 'locations-canvas',
    },
  ];
  let map_rive = document.getElementById('locations-canvas');
  if (map_rive) {
    // Actually initialize those rive instances
    initializeAnimations(animationConfigs);
  }
});
