jQuery(document).ready(function(){
    var page = 2;
    jQuery(function($){
        //  var button = "<a class='btn btn--rounded btn--white load-more' href='#'>Load More</a>";
        // var page = 2;
        var button='<p class="blog__pagination" id="blog__pagination--loading"><span class="btn btn--rounded btn--white original-load loading">Loading...</span></p>';
        function loadPosts(){ 
                
                var data = {
                    action: 'be_ajax_load_more',
                    page: page,
                    query: beloadmore.query,
                };
                $.post(beloadmore.url, data, function(res) {
                    if( res.success) {
                        $('#blog__pagination--loading').remove();
                        $('.blog__posts--top-section').append( res.data );
                        var element = document.getElementById("page-" + page);
                        // element.scrollIntoView(true);
                        // window.scrollBy(0, -100);
                        page = page+1;
                        
                    } else {
                         //console.log(res);
                    }
                }).fail(function(xhr, textStatus, e) {
                     console.log(xhr.responseText);
                });
            
        }
        $('body').on('click', '#blog__pagination--original .original-load', function(e){
            e.preventDefault();
            $('#blog__pagination--original').remove();
            $('.blog__posts--top-section').append(button);
            loadPosts();
           
            
        });
        //click on body bc it is not dynamically generated
        //then see fire even if the child .load-more was clicked
        $('body').on('click', '.load-more', (function(e){
            
            e.preventDefault();
            $('.blog__posts--top-section').append(button);
            $('#blog__pagination').remove();
            
            
            
                var data = {
                    action: 'be_ajax_load_more',
                    page: page,
                    query: beloadmore.query,
                };
                // console.log('data to send', data);
                $.post(beloadmore.url, data, function(res) {
                    if( res.success) {
                        // console.log('res', res);
                        $('#blog__pagination--loading').remove();
                        $('.blog__posts--top-section').append( res.data );
                        var element = document.getElementById("page-" + page);
                        // element.scrollIntoView(true);
                        // window.scrollBy(0, -100);
                        page = page + 1 ;
                        
                    } else {
                         console.log(res);
                    }
                }).fail(function(xhr, textStatus, e) {
                     console.log(xhr.responseText);
                });
            
        }));
            
    });
});
